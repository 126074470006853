import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiLabel } from '../src';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uilabel"
    }}>{`UiLabel`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/text/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Component for rendering labels, commonly used in forms`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/text`}</p>
    </blockquote>
    <h2 {...{
      "id": "uilabel-1"
    }}>{`UiLabel`}</h2>
    <Playground __position={1} __code={'<UiLabel>Some Label</UiLabel>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLabel mdxType="UiLabel">Some Label</UiLabel>
    </Playground>
    <h2 {...{
      "id": "uilabel-with-color-category"
    }}>{`UiLabel with color category`}</h2>
    <Playground __position={2} __code={'<UiLabel category=\"error\">Some label</UiLabel> <br />\n<UiLabel category=\"positive\">Some label</UiLabel> <br />\n<UiLabel category=\"negative\">Some label</UiLabel> <br />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLabel category="error" mdxType="UiLabel">Some label</UiLabel> <br />
  <UiLabel category="positive" mdxType="UiLabel">Some label</UiLabel> <br />
  <UiLabel category="negative" mdxType="UiLabel">Some label</UiLabel> <br />
    </Playground>
    <h2 {...{
      "id": "uilabel-with-default-coloration"
    }}>{`UiLabel with default coloration`}</h2>
    <Playground __position={3} __code={'<UiLabel coloration=\"light\">Will aways render LIGHT coloration</UiLabel>{\' \'}\n<br />\n<UiLabel coloration=\"dark\">Will aways render DARK coloration</UiLabel>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLabel,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiLabel coloration="light" mdxType="UiLabel">Will aways render LIGHT coloration</UiLabel> <br />
  <UiLabel coloration="dark" mdxType="UiLabel">Will aways render DARK coloration</UiLabel>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiLabel} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      